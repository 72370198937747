import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RouteGuard } from './services/route-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    canActivate: [RouteGuard]
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule',
    canActivate: [RouteGuard]
  },
  {
    path: 'item',
    // loadChildren: './item/item.module#ItemPageModule',
    loadChildren: './item/item.module#ItemPageModule',
    canActivate: [RouteGuard]
  },
  // {
  //   path: 'list',
  //   loadChildren: './list/list.module#ListPageModule'
  // },
  {
    path: 'slides',
    loadChildren: './components/slides/slides.module#SlidesPageModule'
  },
  {
    path: 'settings',
    loadChildren: './settings/settings.module#SettingsPageModule',
    canActivate: [RouteGuard]
  },
  // {
  //   path: 'payment',
  //   loadChildren: './payments/payments.module#PaymentsPageModule',
  //   canActivate: [RouteGuard]
  // },
  {
    path: 'terms-and-conditions',
    loadChildren: './terms-and-conditions/terms-and-conditions.module#TermsAndConditionsPageModule',
    canActivate: [RouteGuard]
  },
  {
    path: 'contact-us',
    loadChildren: './contact/contact.module#ContactPageModule'
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginPageModule'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
