import { Injectable } from '@angular/core';
import Amplify, { API, graphqlOperation, APIClass } from 'aws-amplify';
import { AuthenticationService } from './authentication-service';

@Injectable({
  providedIn: 'root'
})
export class TierApiService {

    private localTier: any;

    constructor(private authenticationService: AuthenticationService) { }

    public async shouldSyncToCloud() {
        // const userId = await this.authenticationService.getUserId();

        // if (!userId) {
        //     return false;
        // }

        // const tier = await this.getTier(userId);

        // if (!tier) {
        //     return false;
        // }

        // const shouldSyncToCloud = tier.paid && !tier.synched;

        // return shouldSyncToCloud;
    }

    public clearCache() {
        this.localTier = null;
    }
    public async shouldSyncFromCloud(): Promise<boolean> {
        const userId = await this.authenticationService.getUserId();

        if (!userId) {
            return false;
        }

        const tier = await this.getTier(userId);

        if (!tier) {
            return false;
        }
        const currentDate = new Date();
        const validDate = new Date(new Date(tier.date).setFullYear(new Date(tier.date).getFullYear() + 1));
        const success = tier.status === 'successful';

        console.log('Should Sync from Cloud?:', success && (currentDate > validDate) );

        return success && (currentDate > validDate);
    }

    public async hasCloudAccess(clearCache = false): Promise<boolean> {
        const userId = await this.authenticationService.getUserId();
        console.log('has cloud access eval', userId);

        if (!userId) {
            return false;
        }

        const tier = await this.getTier(userId, clearCache);
        // console.log('cloud access value used to make the descision:', tier);

        if (!tier) {
            return false;
        }
        const currentDate = new Date();
        const validDate = new Date(new Date(tier.date).setFullYear(new Date(tier.date).getFullYear() + 1));
        const success = tier.status === 'successful';
        return success //&& (currentDate < validDate);
    }

    public async getTier(userId: string, clearCache = false) {
        console.log('get tier (local tier):', this.localTier);
        if (this.localTier && !clearCache) {
            return this.localTier;
        }

        const path = `/userManagement/${userId}`;
        const myInit = {
            headers: {'content-type': 'application/json; charset=utf-8'}, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            // queryStringParameters: {  // OPTIONAL
            //     name: 'param'
            // }
        };

        const response = await API.get('neztRestApi', path, myInit);
        console.log('user management data:', response);
        this.localTier = response.data[0];
        return this.localTier;
    }
}
