
import { Injectable, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';

const STORAGE_KEY = 'nezt_login_state';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  public localLoginState = null;

  constructor(
    private storage: Storage
  ) { }

  public async loggedIn() {
    const state = await this.getLoginState();
    return this.isLoggedIn(state);
  }

  public isLoggedIn(state) {
    if (!state) {
      return false;
    }
    return state.state === 'signedIn';
  }

  // public async cloudAccess() {
  //   const state = await this.getLoginState()
  //   return this.hasCloudAccess(state);
  // }

  // public hasCloudAccess(state) {
  //   const access = state.user.signInUserSession.idToken.payload["cognito:groups"] == "CloudAccess"
  //   return access;
  // }

  public async getUser() {
    const state = await this.getLoginState()
    return state.user;
  }

  public async getUsername() {
    const state = await this.getLoginState()
    return state.attributes.name;
  }

  public async getUserId() {
    const state = await this.getLoginState()
    console.log(state);
    return state.user.username;
  }

  public async setLoginState(data) {
    await this.saveToStorage(data);
  }

  public async getLoginState() {
    if (this.localLoginState ) {
      return this.localLoginState;
    }
    try {
      const stateString = await this.storage.get(STORAGE_KEY);
      const state = JSON.parse(stateString);
      this.localLoginState = state;
      return state;
    } catch (error) {
      const state = { state: 'signedOut', user: null };
      this.saveToStorage(state);
      return state;
    }
  }

  public async saveToStorage(state) {
    this.localLoginState = state;
    await this.storage.set(STORAGE_KEY, JSON.stringify(state));
  }
}