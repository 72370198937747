import { settings } from '../models/models';
import { Injectable, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Storage as AWSStorage } from 'aws-amplify';
import { TierApiService } from './tier-api.service';

const STORAGE_KEY = 'settings.json';
const INIT_KEY = 'init.json';
const CLOUD_STORAGE_KEY = 'settings.json';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private localSettings: settings;

    private defaultSettings: settings = {
        currencySymbol: '$'
    };

    constructor(
        private storage: Storage,
        private tierApiService: TierApiService
    ) { }

    public async getSettings(): Promise<settings> {
        // if (this.localSettings) {
        //     console.log('returning cached items items:', this.localSettings)
        //     return this.localSettings;
        // }

        if (await this.tierApiService.hasCloudAccess()) {
            try {
                this.localSettings = await this.getSettingsFromCloud();
            } catch (error) {
                this.localSettings = this.defaultSettings;
            }
            console.log('returning settings from cloud:', this.localSettings)
            return this.localSettings;
        } else {

            try {
                this.localSettings = await this.getSettingsLocalStorage();
            } catch (error) {
                console.log('could not find settings locally, creating a new file...')
                this.localSettings = this.defaultSettings;
                this.saveSettings(this.localSettings);
            }
            console.log('returning storage settings:', this.localSettings)
            return this.localSettings;
        }
    }

    public async saveSettings(settings) {
        if (await this.tierApiService.hasCloudAccess()) {
            return await this.saveSettingsToCloud(settings);
        } else {
            return await this.saveSettingsToLocalStorage(settings);
        }
    }

    //Look at caching this data to save on loading
    public async getSettingsLocalStorage() {
        const storedSettings = await this.storage.get(STORAGE_KEY);
        console.log(storedSettings);
        if (storedSettings) {
            return storedSettings;
        }
        return this.defaultSettings;
    }

    public async saveSettingsToLocalStorage(settings) {
        await this.storage.set(STORAGE_KEY, settings);
    }

    //if logged in, save to cloud as well
    public saveSettingsToCloud(settings) {
        AWSStorage.put(CLOUD_STORAGE_KEY, JSON.stringify(settings), {
            level: 'private',
            contentType: 'text/json'
        })
            .then((result) => { console.log(result) })
            .catch((err) => { console.log(err) });
    }

    public async getSettingsFromCloud(): Promise<settings> {
        const jsonFile = <{ Body: string }>await AWSStorage.get(CLOUD_STORAGE_KEY, { level: 'private', download: true });
        if (jsonFile) {
            const settings = <settings>JSON.parse(jsonFile.Body.toString());
            return settings;
        }
        return this.defaultSettings;
    }

    public async getInitFile() {
        return await this.storage.get(INIT_KEY);
    }

    public async saveInitFile() {
        await this.storage.set(INIT_KEY, 'done');
    }

    public syncDataFromCloud() {
        throw "Not implemented"
    }
}