import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from './authentication-service';
import { Observable } from 'rxjs';
import { SettingsService } from './settings-service';

@Injectable({
    providedIn: 'root'
})
export class RouteGuard implements CanActivate {

    constructor(
        private authenticationService: AuthenticationService,
        private settingsService: SettingsService,
        private router: Router
    ) { }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const loggedIn = this.authenticationService.isLoggedIn(this.authenticationService.localLoginState)
        if (loggedIn) {
            return true;
        } else {
            console.log('Invalid user, redirecting...')
            const init = await this.settingsService.getInitFile()
            if (init)
                this.router.navigate(['/login']);
            else
                this.router.navigate(['/slides']);
            return false;
        }

    }


}
