/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:5c47f5c9-4946-4ce7-a677-3a9fd4d48f84",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_5lFovBTll",
    "aws_user_pools_web_client_id": "6eess4lj832h06q8baonsiffpa",
    "oauth": {},
    "aws_user_files_s3_bucket": "nezt-bucket-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "userManagement-prod",
            "region": "eu-west-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "neztRestApi",
            "endpoint": "https://t1cpwveet9.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://72mhyc7hpvb2pggfkozpoypbae.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
